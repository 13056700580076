import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Tile, H3, P2, B3, StyledLink } from './page-elements';
import truncateString from '../helpers/truncateString';

const MemberTileDiv = styled(Tile)`
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  box-shadow: ${({ theme }) => theme.boxShadow.md};
  display: flex;
  flex-direction: column;

  @media (min-width: 48em) {
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

const Logo = styled(Img)`
  height: 100%;
  width: 100%;
  max-height: 100px;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing[10]};

  @media (min-width: 37.5em) {
    margin-left: auto;
    margin-right: 0;
  }
  @media (min-width: 48em) {
    margin-right: auto;
    max-height: 175px;
    max-width: 300px;
  }
`;

const DetailsContainer = styled.div`
  padding: 0 0;
  flex: 2;
  text-align: left;
  @media (min-width: 24em) {
    padding: 0 ${({ theme }) => theme.spacing[12]};
  }
`;

const CompanyName = styled.h2`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[200]};
  text-transform: uppercase;

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['3xl']};
  }
`;

const TagLine = styled.h3`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary[400]};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing[6]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;

const Bio = styled(P2)`
  margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const MoreInfo = styled(B3)`
  margin: unset;
  margin-left: auto;

  &:before {
    content: none;
  }
`;

const MemberTile = ({ member }) => (
  <>
    <MemberTileDiv>
      <LogoContainer>
        <Logo
          fluid={member.logo.asset.fluid}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </LogoContainer>
      <DetailsContainer>
        <CompanyName>{member.companyName}</CompanyName>
        <TagLine>{member.tagline}</TagLine>
        <Bio>
          {member.description != null &&
            truncateString(member.description, 200, true)}
        </Bio>
        <StyledLink to={`/member/${member.slug && member.slug.current}`}>
          <MoreInfo>More info</MoreInfo>
        </StyledLink>
      </DetailsContainer>
    </MemberTileDiv>
  </>
);
export default MemberTile;
