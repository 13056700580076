import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';

import { H2, H3, P, StyledLink, BackButton } from '../components/page-elements';
import Container from '../components/container';
import Bubbles from '../components/bubbles';
import MemberTile from '../components/memberTile';
import Layout from '../components/layout';

// Functions
// import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[10]};
  text-align: center;
`;
const shuffle = array =>
  [...Array(array.length)]
    .map((...args) => Math.floor(Math.random() * (args[1] + 1)))
    .reduce((a, rv, i) => ([a[i], a[rv]] = [a[rv], a[i]]) && a, array);

const MembersPage = ({ data }) => {
  const membersData = [...data.allSanityMember.nodes];
  return (
    <Layout>
      <>
        <SEO title="Members" />
        <Bubbles memberData={membersData} style={{ transform: `scale(0.5)` }} />
        <StyledContainer>
          <H2>Members</H2>
          <P>
            AIMRO members include most of the main professional and focused
            insight providers in Ireland.
          </P>
          {shuffle(data.allSanityMember.nodes).map((member, index) => (
            <MemberTile member={member} />
          ))}
          <StyledLink to="/">
            <BackButton>Go back to the homepage</BackButton>
          </StyledLink>
        </StyledContainer>
      </>
    </Layout>
  );
};

export const MembersPageQuery = graphql`
  {
    allSanityMember {
      nodes {
        slug {
          current
        }
        companyName
        tagline
        description
        iconLogo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        iconBGColour {
          hex
        }
        logo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        website
        phone
        email
        twitter
        linkedin
      }
    }
  }
`;

export default MembersPage;
